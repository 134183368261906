/*
 * @Descripttion: 
 * @Author: zhongbin.chen
 * @Date: 2021-09-9 11:24:58
 */
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { commonRequest } from './index'
// 任务详情
export const taskInfo = (params: any) => {
	return commonRequest('robot-status/task_detail', Method.GET, params, ContentType.form)
}
// 任务状态结束时候查询
export const taskstatus  = (params: any) => {
	return commonRequest('robot-status/task_alone', Method.GET, params, ContentType.form)
}
/*-----------区域----------*/
// 区域列表
export const regionList = (params: any, isError?: boolean) => {
	return commonRequest('area', Method.GET, params, ContentType.form, isError)
}
// 创建区域
export const createRegion = (params: any) => {
  return commonRequest('area', Method.POST, params, ContentType.json)
}
// 删除区域
export const delRegion = (areaids: any, params: any) => {
	return commonRequest('area/'+areaids, Method.DELETE, params, ContentType.form)
}
// 更新区域
export const editRegion = (data: any) => {
  return commonRequest('area/'+data.areaid, Method.PUT, data.params, ContentType.json)
}
// 区域详情
export const regionInfo = (areaid: any, params: any) => {
	return commonRequest('area/'+areaid , Method.GET, params, ContentType.form)
}
/*-----------房间----------*/
// 房间列表
export const roomList = (params: any, isError?: boolean) => {
	return commonRequest('area/room/all/'+params.areaid, Method.GET, params, ContentType.form, isError)
}
export const newroomlist = (params:any) => {
	return commonRequest(`area/${params.areaid}/room_list?sn=${params.sn}&pageSize=${params.pageSize}&page=${params.page}`, Method.GET, params, ContentType.json)
}
// 创建房间
export const createRoom = (params: any) => {
  return commonRequest('area/room/'+params.areaid, Method.POST, params, ContentType.json)
}
// 删除房间
export const delRoom = (params: any, data: any) => {
	return commonRequest('area/room/'+params.room_id, Method.DELETE, data, ContentType.form)
}
// 更新房间
export const editRoom = (data: any) => {
  return commonRequest('area/room/'+data.roomid, Method.PUT, data.params, ContentType.json)
}
// 房间详情
export const roomInfo = (params: any) => {
	return commonRequest('area/room/'+params.roomid , Method.GET, params, ContentType.form)
}
/*-----------单个房间-地图列表----------*/
//地图列表
export const roomMaplist = (params:any) => {
	return commonRequest(`area/room/map/${params.room}`, Method.GET, params, ContentType.form)
}
// 重命名地图
export const reNameMap = (params: any) => {
	return commonRequest('area/room/map/'+params.room, Method.PUT, params.body, ContentType.json)
}
// 删除地图
export const delRoomMap = (params: any, data: any) => {
	return commonRequest('area/room/map/'+params.room+'/'+params.mapids, Method.DELETE, data, ContentType.form)
}
// 新增地图
// export const newMap = (params: any) => {
//   return commonRequest('area/room/map/'+params.room, Method.PUT, params.params, ContentType.json)
// }
// 地图详情
export const roomMapInfo = (params: any) => {
	return commonRequest('area/room/'+params.roomid , Method.GET, params, ContentType.form)
}

// 创建任务
export const createTasks = (sn: any,params: any) => {
	return commonRequest(`tasks?sn=${sn}` , Method.POST, params, ContentType.json)
}
// 一键回桩获取taskid
export const createdockdetail = (sn: any,params: any) => {
	return commonRequest(`robot-setting/dock_on?sn=${sn}` , Method.POST, params, ContentType.json)
}
// 操作任务 开始 结束 暂停 taskid 为获取创建任务成功id  cmd操作任务
export const  Operationtasks = (taskid: string,operation: string,sn: string,params: any) => {
	return commonRequest(`task/${taskid}?cmd=${operation}&sn=${sn}` , Method.POST, params, ContentType.json)
}
// 删除任务 
export const deletetaskrw = (taskid: string,params: any) => {
	return commonRequest(`task/${taskid}` , Method.DELETE, params, ContentType.json)
}
// 查询当前是否有任务
export const currenttask = (sn:string,params: any) =>{
	return commonRequest(`current_task?sn=${sn}`,Method.GET, params, ContentType.json)
}
// 关机接口
export const turnOff = (sn: string,params: any) => {
	return commonRequest(`robot-setting/turn_off?sn=${sn}` , Method.POST, params, ContentType.json)
}
// 获取定时任务列表
export const scheduledTasks= (params: any) => {
	return commonRequest('tasks/scheduled-tasks', Method.GET, params, ContentType.form)
}
// 删除本体任务
export const removetimingtask = (params:any) =>{
	return commonRequest(`tasks/scheduled-tasks/${params.taskid}?sn=${params.sn}`, Method.DELETE, params, ContentType.json)
}
// 任务参数
export const taskiddetail = (params:any) =>{
	return commonRequest(`tasks/timer_task?sn=${params.sn}&name=${params.name}`, Method.GET, params, ContentType.json)
}
// 编辑任务
export const tasksedit = (taskid:any,sn:any,params:any) =>{
	return commonRequest(`tasks/edit?sn=${sn}&taskid=${taskid}`, Method.POST, params, ContentType.json)
}
// 获取位置点列表
export const reportList = (params: any) => {
	return commonRequest('robot-status/report_list', Method.GET, params, ContentType.form)
}
// 获取历史点位置列表
// 获取位置点列表
export const nowMapList = (params: any) => {
	return commonRequest('robot-status/report_points', Method.GET, params, ContentType.form)
}
// 获取门禁牌绑定信息
export const getmjpmac = (params: any) => {
	// robot-setting/access?sn=CAI001UBT10000016&option=connected
	return commonRequest('robot-setting/access', Method.GET, params, ContentType.form)
}
// 消杀房间全量列表
export const regionAndRoomList = (params: any) => {
	return commonRequest('all_room', Method.GET, params, ContentType.form)
}
// 获取机器人软件序列号id 短码
export const robotssids = (params:any) =>{
	return commonRequest(`robots/${params}/ssid`, Method.GET, params, ContentType.form)
}
// 主动获取机器人信息
export const refreshrobt = (params:any) =>{
	return commonRequest(`robot-status/refresh?sn=${params.sn}`, Method.POST, undefined, ContentType.form)
}
// 主动查询机器人当前状态
export const robotrbts = (params:any) =>{
	return commonRequest(`robot-status/rbt?sn=${params}`, Method.GET, params, ContentType.json)
}
// 查询梯控结果
export const robotelevator = (params:any) =>{
	return commonRequest(`tasks/elevator?sn=${params.sn}&name=${params.name}`, Method.GET, params, ContentType.json)
}
// 获取地图虚拟墙点位
export const getmaps = (params:any) =>{
	return commonRequest(`robot-status/map_info?sn=${params.sn}&roomid=${params.room_id}&mapname=${params.mapname}`, Method.GET, params, ContentType.json)
}
// 获取地图中机器人实时位置
export const getpos = (params:any) =>{
	return commonRequest(`robot-status/pos?sn=${params.sn}`, Method.GET, params, ContentType.json)
}